@font-face {font-family: 'TTRoundsNeue-ExtraBold';src: url('./webfonts/384F0C_1_0.eot');src: url('./webfonts/384F0C_1_0.eot?#iefix') format('embedded-opentype'),url('./webfonts/384F0C_1_0.woff2') format('woff2'),url('./webfonts/384F0C_1_0.woff') format('woff'),url('./webfonts/384F0C_1_0.ttf') format('truetype');}
@font-face {font-family: 'TTRoundsNeue-Medium';src: urlsa('./webfonts/384F0C_0_0.eot');src: url('./webfonts/384F0C_0_0.eot?#iefix') format('embedded-opentype'),url('./webfonts/384F0C_0_0.woff2') format('woff2'),url('./webfonts/384F0C_0_0.woff') format('woff'),url('webfonts/384F0C_0_0.ttf') format('truetype');}


body {
  margin: 0;
  font-family: "Poppins", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3b4b52;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swagger-ui .info li, .swagger-ui .info p, .swagger-ui .info table{
  font-family: "Poppins", Sans-serif !important;
  font-size: 16px !important;
}

.main {
  display:none;
}

._title{
  font-family: "TTRoundsNeue-ExtraBold", Sans-serif;
}

._titleMed{
  font-family: "TTRoundsNeue-Medium", Sans-serif;
}

h1._titleMed {
  font-size: 2em;
  margin:0 0 0 10px !important;
}
a._sign{
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 2.3em;
  color: #3b4b52;
  background-color: #ffd400 !important;
  border-radius: 24px 24px 24px 24px;
  padding: 0px 35px 0px 35px;
  padding: 0px 50px 0px 49px;
  height: 40px;
  text-decoration: none;
  float: right;
  margin-left: auto;
}
